<template>
  <div class="app">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h2>Lista de Recepção</h2>
    <b-button class="add" @click="routeToCreateInvoice">Criar fatura</b-button>
    <div class="search">
      <div class="row">
        <!-- <div class="col-sm-1"></div> -->
        <div class="col-sm-3">
          <div class="form-group">
            <label for="provider">Fornecedor</label>
            <v-select :options="suppliers" v-model="supplier" :reduce="(supp) => supp.name" label="name">
              <template slot="option" slot-scope="option"> {{ option.name }}</template>
              <template slot="selected-option" slot-scope="option"> {{ option.name }}</template>
            </v-select>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="provider">Nº da fatura</label> <br />
            <input type="text" class="form-control" v-model="invoiceId" />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="">Estado</label> <br />
            <select class="form-select form-select" aria-label=".form-select-lg example" v-model="status">
              <option selected value="">Sem estado</option>
              <option value="processing">Pendentes</option>
              <option value="completed">Completas</option>
              <option value="new">Novas</option>
            </select>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="">Data Inicial</label> <br />
            <input type="date" class="form-control" v-model="date_i" />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="">Data Final</label> <br />
            <input type="date" class="form-control" v-model="date_f" />
          </div>
        </div>
        <div class="col-sm-1">
          <div class="form-group">
            <label for="">Sem Fatura</label> <br />
            <input type="checkbox" v-model="noInvoice" />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="">Nota de Crédito</label> <br />
            <select class="form-select form-select" aria-label=".form-select-lg example" v-model="creditNoteStatus">
              <option selected value=""></option>
              <option value="allCreditNote">Todas com Nota de Crédito</option>
              <option value="creditNotePendent">Pendente Nota de Crédito</option>
              <option value="creditNoteInserted">Nota de Crédito Inserida</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-success" @click="downFile()" style="margin: 5px">Relatório</button>
    <button class="btn btn-warning" @click="goEncomendas()" style="margin: 5px">Encomendas</button>
    <button class="btn btn-primary" @click="filterSearch()" style="margin: 5px">Pesquisar</button>
    <b-pagination
      v-model="currentPage"
      pills
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      align="center"
    ></b-pagination>
    <b-table class="table" :items="invoicesSuppliers" :fields="fields" :per-page="perPage" :current-page="currentPage">
      <template #cell(invoice_id)="data">
        <span v-if="data.item.invoice_id == 'null'"></span>
        <span v-else>{{ data.item.invoice_id }}</span>
        <!-- {{ dataitem.id }} -->
      </template>
      <template #cell(date_received)="data">
        <span v-if="data.item.date_received == null"></span>
        <span v-else>{{ splitDate(data.item.date_received) }}</span>
      </template>
      <template #cell(status)="data">
        <span v-if="data.item.status == 'completed'">Completa</span>
        <span v-else-if="data.item.status == 'processing'">Pendente</span>
        <span v-else-if="data.item.status == 'new'">Novas</span>
        <span v-else-if="data.item.status == 'cancelled'">Cancelada</span>
      </template>
      <template #cell(plus)="data">
        <button type="button" class="btn btn-primary" @click="openEditReceptionOrder(data.item.id)">+</button>
        <!-- {{ dataitem.id }} -->
      </template>
      <template #cell(edit)="data">
        <button type="button" class="btn btn-success" @click="editPurchase(data.item.id)">✏️</button>
        <!-- {{ dataitem.id }} -->
      </template>
      <template #cell(date_request)="data">
        <span v-if="data.item.date_request == null">{{ splitDate(data.item.date_received) }}</span>
        <span v-else>{{ splitDate(data.item.date_request) }}</span>
      </template>
      <template #cell(transactions)="data">
        <b-dropdown text="Ver Produtos" class="m-md-2">
          <b-dropdown-item v-for="detail in data.item.transactions" :key="detail.sku">
            {{
              detail.qty_confirmed !== undefined && detail.qty_confirmed !== null
                ? detail.qty_confirmed
                : detail.qty_received
            }}
            x {{ detail.sku }} - {{ detail.name }}
          </b-dropdown-item>
        </b-dropdown>
        <br />
        <span
          v-if="
            data.item.transactions &&
            data.item.transactions.length > 0 &&
            data.item.transactions.reduce((sum, prod) => {
              return sum + Number(prod.qty_confirmed);
            }, 0) !== 0 &&
            !isNaN(
              data.item.transactions.reduce((sum, prod) => {
                return sum + Number(prod.qty_confirmed);
              }, 0)
            )
          "
        >
          {{
            data.item.transactions.reduce((sum, prod) => {
              return sum + Number(prod.qty_confirmed);
            }, 0)
          }}
        </span>
        <span v-else>
          {{
            data.item.transactions
              ? data.item.transactions.reduce((sum, prod) => {
                  return sum + Number(prod.qty_received);
                }, 0)
              : 0
          }}
        </span>
      </template>
      <template #cell(delete)="data">
        <span v-if="data.item.status == 'new' || data.item.status == 'processing'">
          <button class="btn btn-danger" @click="removeReceptions(data.item.id, data.item.supplier, data.item.total)">
            X
          </button>
        </span>
      </template>
      <template #cell(credit_note)="data">
        <span v-if="data.item.credit_note == false || data.item.credit_note == null"
          ><button class="btn btn-primary activate-credit-note" @click="activateCreditNote(data.item.id)">
            Ativar Nota de Crédito
          </button></span
        >
        <span
          v-else-if="
            data.item.credit_note == true &&
            (data.item.credit_note_number !== null || data.item.credit_note_value !== null)
          "
          >Nota de Crédito Adicionada</span
        >
        <span v-else-if="data.item.credit_note == true">Espera Nota de Crédito</span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      pills
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      align="center"
    ></b-pagination>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
import { mapGetters } from 'vuex';
import html2pdf from 'html2pdf.js';
import exportFromJSON from 'export-from-json';
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      invoicesSuppliers: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'bill_id',
          label: 'Nº Fatura',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'date_request',
          label: 'Pedido',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'date_received',
          label: 'Receção',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'supplier',
          label: 'Fornecedor',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Estado',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'transactions',
          label: 'Artigos',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'total',
          label: 'Pago',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'payment_method',
          label: 'Pagamento',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'plus',
          label: '',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'edit',
          label: '',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'credit_note',
          label: 'Nota de Crédito',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
        {
          key: 'delete',
          label: '',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
      ],
      loading: false,
      suppliers: [],
      supplier: '',
      invoiceId: '',
      status: 'processing',
      currentPage: 1,
      perPage: 20,
      rows: 1,
      date: '',
      noInvoice: false,
      creditNoteStatus: '',
    };
  },
  methods: {
    async goEncomendas() {
      window.open(`https://${window.location.host}/purchases/trans`, '_blank');
    },
    async getAllInvoicesSuppliers() {
      this.loading = true;
      try {
        await this.$store.dispatch('getAllInvoicesSuppliers');
        console.log(this.getInvoicesSuppliers);
        this.invoicesSuppliers = this.getInvoicesSuppliers;
        this.rows = this.invoicesSuppliers.length;
        console.log(this.invoicesSuppliers);
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    async downFile() {
      let today = new Date();
      let date_i = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
      let array = [];
      for (const invoice of this.invoicesSuppliers) {
        console.log('FATURA: ', invoice);
        let obj = {
          id: invoice.id,
          fatura: invoice.invoice_id,
          fornecedora: invoice.supplier,
          pedido: invoice.date_request,
          receção: invoice.date_received,
          estado: invoice.status,
          total_recepção: invoice.total,
          total_pago: invoice.total_pay,
          total_fatura: invoice.invoice_value,
          metodo_pagamento: invoice.payment_method,
        };
        array.push(obj);
        console.log(obj);
      }
      console.log(array);
      let data = array;
      const fileName = `Relatório_${date_i}`;
      const exportType = exportFromJSON.types.csv;
      exportFromJSON({ data, fileName, exportType });
    },
    async createPDF() {
      this.$swal({
        title: 'Quer abrir o relatório?',
        text: 'O ficheiro será baixado!',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let today = new Date();
          let date_i = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
          let html = `<table style="width:100%; font-size:50%">
  <tr>
    <th>ID</th>
    <th>NºFatura</th>
    <th>Fornecedor</th>
    <th>Pedido</th>
    <th>Recepção</th>
    <th>Estado</th>
  </tr>
`;
          for (const [i, invoice] of this.invoicesSuppliers.entries()) {
            if (i != 0 && i % 87 == 0) {
              html =
                html +
                `   <tr>
                    <td>${invoice.id}</td>
                    <td>${invoice.invoice_id}</td>
                    <td>${invoice.supplier}</td>
                    <td>${this.splitDate(invoice.date_request)}</td>
                    <td>${this.splitDate(invoice.date_received)}</td>
                    <td>${invoice.status}</td>
                    </tr> </table>`;
              html = html + `<div class="html2pdf__page-break"></div>`;
              html =
                html +
                `<table style="width:100%; font-size:50%">
  <tr>
    <th>ID</th>
    <th>NºFatura</th>
    <th>Fornecedor</th>
    <th>Pedido</th>
    <th>Recepção</th>
    <th>Estado</th>
  </tr>`;
            } else {
              html =
                html +
                `   <tr>
                    <td>${invoice.id}</td>
                    <td>${invoice.invoice_id}</td>
                    <td>${invoice.supplier}</td>
                    <td>${this.splitDate(invoice.date_request)}</td>
                    <td>${this.splitDate(invoice.date_received)}</td>
                    <td>${invoice.status}</td>
                    </tr>`;
            }
          }
          console.log(html);
          html = html + `</table>`;
          html2pdf(html, {
            margin: 6,
            filename: `Relatório_${date_i}.pdf`,
            html2canvas: {
              scale: 2,
              logging: true,
              dpi: 192,
              letterRendering: true,
            },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
          });
        }
      });
    },
    async removeReceptions(id, supp, total) {
      console.log('REMOVER RECEPÇÃO');
      this.$swal({
        title: 'Quer remover esta receção ?',
        text: 'Após apagar não poderá reverter!',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, eliminar!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          //Get supplier id;
          const supplier = await this.getSuppliers.find((item) => item.name == supp);
          const today = new Date().toISOString().substr(0, 10); // Get today's date in YYYY-MM-DD format
          const refundConfirmationResult = await this.$swal({
            title: 'Já recebeu a devolução da encomenda?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, recebi!',
            cancelButtonText: 'Não recebi',
          });
          if (refundConfirmationResult.isConfirmed) {
            const formResult = await this.$swal({
              title: 'Valor da devolução',
              cancelButtonText: 'Cancelar',
              showCancelButton: true,
              html: `<form id="myForm">
                <label for="valueInput">Valor Devolvido:</label><br>
                <input type="number" step="0.01" id="valueInput" name="valueInput" value="${total}"><br>
                <label for="dateInput">Data da Devolução:</label><br>
                <input type="date" id="dateInput" name="dateInput" value="${today}">
               </form>`,
              preConfirm: () => {
                const valueInput = document.getElementById('valueInput').value;
                const dateInput = document.getElementById('dateInput').value;
                if (!valueInput || !dateInput) {
                  this.$swal.showValidationMessage(`Please enter both a value and a date`);
                }
                return { value: valueInput, date: dateInput };
              },
            });
            if (!formResult.isConfirmed) {
              this.loading = false;
              return;
            }
            try {
              await this.$store.dispatch('addAccounting', {
                supplier: supplier.id,
                document: 'Recebimento',
                value: formResult.value.value,
                reception_id: id,
                invoice: this.paymentMethod,
                date: formResult.value.date,
              });
            } catch (err) {
              alert(err);
            }
          }
          try {
            await this.$store.dispatch('removeReception', { id: id });
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: 'Fatura Removida',
              showConfirmButton: false,
              timer: 1500,
            });
            // await this.getAllInvoicesSuppliers(true);
            await this.filterSearch();
          } catch (err) {
            alert(err);
          } finally {
            this.loading = false;
          }
        }
      });
    },
    async filterSearch() {
      console.log('ABRIU PESQUISA');
      console.log(this.status);
      console.log(this.supplier);
      console.log(this.invoiceId);
      let supp_id = '';
      if (this.supplier == null || this.supplier == undefined || this.supplier == '') {
        this.supplier = '';
      } else {
        const id_supp = this.suppliers.filter((supp) => supp.name == this.supplier);
        supp_id = id_supp[0].id;
      }
      if (this.date_i == '' || this.date_i == null || this.date_i == undefined) {
        let today = new Date();
        today.setTime(today.getTime() - 30 * 24 * 60 * 60 * 1000);
        this.date_i = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
      }
      if (this.date_f == '' || this.date_f == null || this.date_f == undefined) {
        let today = new Date();
        this.date_f = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
      }
      this.loading = true;
      console.log('Pesquisar');
      try {
        await this.$store.dispatch('searchReception', {
          invoiceID: this.invoiceId,
          supplier: supp_id,
          status: this.status,
          start_date: this.date_i,
          final_date: this.date_f,
          noInvoice: this.noInvoice,
          creditNoteStatus: this.creditNoteStatus,
        });
        this.invoicesSuppliers = this.getSearchReception;
        this.rows = this.invoicesSuppliers.length;
        console.log(this.getSearchReception);
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    splitDate(date) {
      let newDate = new Date(date.split('T'));
      let year = newDate.getFullYear();
      let month = ('0' + (newDate.getMonth() + 1)).slice(-2);
      let day = ('0' + newDate.getDate()).slice(-2);
      return `${day}-${month}-${year}`;
    },
    async openEditReceptionOrder(id) {
      // this.$router.push(`/receptionProducts/${id}/update`);
      window.open(`https://${window.location.host}/receptionProducts/${id}/update`, '_blank');
    },
    routeToCreateInvoice() {
      this.$router.push({ path: '/receptionProducts/add' });
    },
    async getAllSuppliers() {
      try {
        await this.$store.dispatch('getAllSuppliers');
        console.log(this.getSuppliers);
        let obj;
        for (let index = 0; index < this.getSuppliers.length; index++) {
          const element = this.getSuppliers[index];
          obj = {
            id: element.id,
            name: `${element.name} (${element.other_name})`,
          };
          this.suppliers.push(obj);
        }
        let today = new Date();
        this.date_f = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        today.setTime(today.getTime() - 30 * 24 * 60 * 60 * 1000);
        this.date_i = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        // this.date = [date_f, date_i];
      } catch (error) {
        alert(error);
      }
    },
    editPurchase(id) {
      window.open(`https://${window.location.host}/receptionsProducts/${id}/updateData`, '_blank');
      // this.$router.push(`/receptionsProducts/${id}/updateData`);
    },
    async activateCreditNote(id) {
      this.$swal({
        title: 'Tem a certeza que quer ativar a Nota de Crédito?',
        text: 'Após ativar não poderá reverter!',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, ativar!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('activateCreditNote', { id: id });
            this.$swal({
              toast: true,
              position: 'top-end',
              icon: 'success',
              title: 'Nota de Crédito Ativada',
              showConfirmButton: false,
              timer: 1500,
            });
            let findIndex = this.invoicesSuppliers.findIndex((invoice) => invoice.id == id);
            this.invoicesSuppliers[findIndex].credit_note = true;
          } catch (err) {
            alert(err);
          }
        }
      });
    },
  },
  computed: {
    ...mapGetters(['getInvoicesSuppliers']),
    ...mapGetters(['getSuppliers']),
    ...mapGetters(['getSearchReception']),
  },
  async created() {
    this.loading = true;
    // await this.getAllInvoicesSuppliers();
    await this.getAllSuppliers();
    await this.filterSearch();
    this.loading = false;
  },
};
</script>

<style>
.app {
  text-align: center;
  margin: 0px 50px 0px 50px;
}
.pending {
  margin: 30px 5px 0px 5px;
  background-color: blue;
}
.finish {
  margin: 30px 5px 0px 5px;
}
.table {
  margin-top: 30px;
}
.add {
  margin: 30px 5px 0px 5px;
}
.search {
  margin: 30px 80px 0px 80px;
  text-align: center;
}
.search label {
  font-weight: bold;
}
</style>
